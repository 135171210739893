import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout darkText>
    <h1>404</h1>
    <p>not found</p>
  </Layout>
);

export default NotFoundPage;
